
import media from "../../helpers/media";
import CoreBlockVideo from "./CoreBlockVideo.vue";

export default {
	name: "CoreBlockImage",
	components: { CoreBlockVideo },
	props: {
		settings: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			croppedImage: null,
		};
	},
	computed: {
		imageAlt() {
			const mediaSrc = this.settings?.src;
			return mediaSrc && typeof mediaSrc === "object"
				? mediaSrc.alt
				: this.settings?.alt || "";
		},
		imageSrc() {
			const mediaSrc = this.settings.src;
			return mediaSrc && typeof mediaSrc === "object"
				? mediaSrc.src
				: mediaSrc;
		},
		croppedSrc() {
			return this.backgroundMediaCrop?.image
				? this.croppedImage
				: this.imageSrc;
		},
		imageHeight() {
			return (
				this.settings?.extraProps?.height ||
				this.settings.height ||
				null
			);
		},
		imageWidth() {
			return (
				this.settings?.extraProps?.width || this.settings.width || null
			);
		},
		mediaBackgroundPosition() {
			return this.backgroundMediaFocal?.bgPosition || "center";
		},
		backgroundMediaFocal() {
			return this.backgroundMediaCrop?.focal;
		},
		backgroundMediaCrop() {
			const mediaSrc = this.settings?.src || "";
			const { crop, focal } = mediaSrc;
			if (crop || focal) {
				const mediaObject = { ...crop };
				if (focal) mediaObject.focal = focal;
				return mediaObject;
			}
			return this.settings?.mediaMetadata?.src;
		},
		mediaIsVideo() {
			return media.getMediaTypeFromSrc(this.imageSrc) === "video";
		},
	},
	methods: {
		loadImage(image) {
			const src = image.getAttribute("data-src");
			image.src = src;
			image.removeAttribute("data-src");
		},
		updateCroppedImage(croppedImg) {
			if (!croppedImg) {
				return (this.$refs.imageComponent.src = this.imageSrc);
			}
			this.croppedImage = croppedImg;
			this.$refs.imageComponent.src = croppedImg;
		},
	},
};
